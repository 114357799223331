import React from 'react';
import Hero from '../components/Layout/Hero';
import Grid from '../components/Layout/Grid';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { siteKey } from '../captchaconfig';

const tabItems = [
    { 
        name: 'KONTAKT',
        id: 'contact',
        href: 'contact'
    },
    { 
        name: 'BEWERBUNG',
        id: 'bewerbung',
        href: 'bewerbung'
    },
];



class Application extends React.Component {

    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);


        this.state = {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            phone: undefined,
            address: undefined,
            position: undefined,
            ms: undefined,
            availableFrom: undefined,
            resume: undefined,
            picture: undefined,





            sent: false,
            error: null,
            isHuman: '[empty]',
            expired: false
        }
    }

    handleChange = isHuman => {
        this.setState({ isHuman });
        // if value is null recaptcha expired
        if (isHuman === null) this.setState({ expired: true });
    };

    shouldLoadCatpthcha = () => {
        const { isHuman, expired } = this.state;
        return expired || isHuman === '[empty]' || isHuman === null;
    }



    // uploadfile = () => {
    //     const file = this.refs.file.getDomNode().files[0];
    //     const reader = new FileReader();

    //     reader.onload = (output) => {
    //         fileUpload.set()
    //     }
    // }


    handleSubmit = (e) => {
        e.preventDefault();


        const { firstName, lastName, email, phone, address, ms, resume, picture } = this.state;

        const name = `${firstName} ${lastName}`;
        const message = `
        email: ${email}
        phone: ${phone}
        address: ${address}
        -----------------------------------
        ${ms}
        `
        const data = {
            name,
            email,
            message,
            resume,
            picture
        }
        console.log(data);
        axios({
            method: 'post',
            url: './handler.php',
            headers: {'content-type': 'application/json'},
            data
        }).then(result => {
            this.setState({ sent: true});
            console.log(result)
        }).catch((err) => { 
            this.setState({ error: "an error occured please try again"});
            console.log(err);
        });
    }


    setField = (type, value) => {
        this.setState({  [type]: value});
    }


    renderNameRow() {
        const { firstName, lastName } = this.state;
        const label = (
            <div className="field-label is-normal">
                <label className="label">Persönliche Daten</label>
            </div>
        )
        return (
            <div className="field is-horizontal">
                {label}
                <div className="field-body">
                    <div className="field">
                        <p className="control is-expanded has-icons-left">
                            <input className="input" type="text" placeholder="Vorname" value={firstName} onChange={e => this.setField('firstName', e.target.value) } />
                            <span className="icon is-small is-left">
                                <i className="fa fa-user" />
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                            <input className="input" type="text" placeholder="Nachname" value={lastName}  onChange={e => this.setField('lastName', e.target.value) } />
                            <span className="icon is-small is-left">
                                <i className="fa fa-user" />
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    renderContactData() {
        const { email, phone } = this.state;
        const label = (
            <div className="field-label is-normal">
                <label className="label" />
            </div>
        )
        return (
            <div className="field is-horizontal">
                {label}
                <div className="field-body">
                    <div className="field">
                        <p className="control is-expanded has-icons-left">
                            <input className="input" type="email" placeholder="Email" value={email} onChange={e => this.setField('email', e.target.value) } />
                            <span className="icon is-small is-left">
                                <i className="fa fa-envelope" />
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                            <input className="input" type="tel" placeholder="Telefon" value={phone}  onChange={e => this.setField('phone', e.target.value) } />
                            <span className="icon is-small is-left">
                                <i className="fa fa-phone" />
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    renderAdress() {
        const {address} = this.state;
        const label = (
            <div className="field-label is-normal">
                <label className="label" />
            </div>
        );
        return (
            <div className="field is-horizontal">
                {label}
                <div className="field-body">
                    <div className="field">
                        <div className="field">
                        <p className="control is-expanded has-icons-left">
                            <input className="input" type="tel" placeholder="Straße, Nummer, PLZ, Ort" value={address} onChange={e => this.setField('address', e.target.value)} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-map-marker" />
                            </span>
                        </p>
                        </div>
                        </div>
                    </div>
            </div>
        );
    }


    handleAvatarUpload = (e) =>  {
        this.setState({ picture: e.target.files[0]});
    }

    handleResumeUpload = (e) =>  {
        this.setState({ resume: e.target.files[0]});
    }
    renderPhoto() {

        const { picture } = this.state;
        const label = (
            <div className="field-label is-normal">
                <label className="label">Unterlagen</label>
            </div>
        );

        return (
            <div className="field is-horizontal">
                {label}
                <div className="field-body">
                    <div className="field">
                        <div className="file is-primary is-fullwidth">
                            <label className="file-label">
                            <input className="file-input" type="file" name="photo" onChange={this.handleAvatarUpload} />
                            <span className="file-cta">
                                <span className="file-icon">
                                <i className="fa fa-upload" />
                                </span>
                                <span className="file-label">
                                    Foto
                                </span>
                            </span>
                                {picture !== undefined && picture.name !== undefined && 
                                    <span>&nbsp; {picture.name} </span>
                                }
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        );
    }


    renderResume () {
        const { resume } = this.state;
        const label = (
            <div className="field-label is-normal">
                <label className="label" />
            </div>
        );
 

        return (
            <div className="field is-horizontal">
                {label}
                <div className="field-body">
                    <div className="field is-fullwidth">
                        <p className="file is-boxed">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume" onChange={this.handleResumeUpload} />
                                <span className="file-cta">
                                    <span className="file-icon">
                                    <i className="fa fa-upload" />
                                    </span>
                                    <span className="file-label">
                                        Lebenslauf auswählen
                                    </span>
                                </span>
                            </label>
                            {resume !== undefined && resume.name !== undefined && (
                                <span>&nbsp; {resume.name}</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        )

    }


    renderJobDetail() {
        const { availableFrom, position } = this.state;

        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal" />
                <div className="field-body">
                    <div className="field is-narrow">
                        <p className="control has-icons-left">
                            <input className="input" type="text" placeholder="Verfügbar Ab" value={undefined} onChange={e => this.setField('firstName', e.target.value) } />
                            <span className="icon is-small is-left">
                                <i className="fa fa-calendar" />
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    renderJobSelect() {
        const {position } = this.state;

        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal" />
                <div className="field-body">
                    <div className="field">
                        <p className="control">
                            <div className="select">
                                <select>
                                    <option>Business development</option>
                                    <option>Marketing</option>
                                    <option>Sales</option>
                                </select>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        )
    }


    renderAdditional() {

        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Motivationsschreiben</label>
                </div>
                <div className="field-body">
                    <div className="field">
                    <div className="control">
                        <textarea className="textarea" defaultValue={""} />
                    </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Grid>
                <Hero bgClass="default" tabItems={tabItems} heroContent={(<React.Fragment><h2>Kontakt</h2></React.Fragment>)}/>
                <main>
                    <section class="section">
                        <div className="container">
                            <form onSubmit={this.handleSubmit} >

                            {this.renderNameRow()}
                            {this.renderContactData()}
                            {this.renderAdress()}
                            {this.renderAdditional()}
                            {/**  this.renderJobSelect() */}
                            {this.renderPhoto()}
                            {this.renderResume()}
                            <div className="field is-horizontal">
                                    <div className="field-label isnormal"/>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                {this.shouldLoadCatpthcha() &&
                                                    <ReCAPTCHA
                                                        style={{ display: "inline-block" }}
                                                        theme="dark"
                                                        sitekey={siteKey}
                                                        onChange={this.handleChange}
                                                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                    /> 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <div className="field is-horizontal">
                            <div className="field-label">
                                {/* Left empty for spacing */}
                            </div>
                            <div className="field-body">
                                <div className="field">
                                <div className="control">
                                    <button disabled={!this.shouldLoadCatpthcha()} type="submit" className="button is-primary" style={{ padding: '0.2em 1em'}}>
                                        Bewerbung Senden 
                                    </button>
                                </div>
                                </div>
                            </div>
                            </div>
                            </form>
                        </div>
                    </section>
                </main>
            </Grid>
        );
    }
}




export default Application;