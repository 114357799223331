import React from 'react';
import Hero from '../components/Layout/Hero';
import Grid from '../components/Layout/Grid';
import ReCAPTCHA from "react-google-recaptcha";


import axios from 'axios';
import { siteKey } from '../captchaconfig';



const tabItems = [
    { 
        name: 'KONTAKT',
        id: 'contact',
        href: 'contact'
    },
    { 
        name: 'BEWERBUNG',
        id: 'bewerbung',
        href: 'bewerbung'
    },
];

class Contact extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.shouldLoadCatpthcha = this.shouldLoadCatpthcha.bind(this);

        this.state = {
            name: undefined,
            email: undefined,
            message: undefined,
            sent: false,
            error: null,
            isHuman: '[empty]',
            expired: false
        }
    }

    handleChange = isHuman => {
        this.setState({ isHuman });
        // if value is null recaptcha expired
        if (isHuman === null) this.setState({ expired: true });
    };

    shouldLoadCatpthcha = () => {
        const { isHuman, expired } = this.state;
        return expired || isHuman === '[empty]' || isHuman === null;
    }

    handleSubmit(e) {
        e.preventDefault();
        axios({
            method: 'post',
            url: './contact.php',
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(result => {
                this.setState({
                sent: result.data.sent
            })
        })
        .catch(error => this.setState({ error: error.message }));
    }

    render() {
        const { name, email, message} = this.state;
        return (
            <Grid>
                <Hero 
                    bgClass="default" 
                    tabItems={tabItems} 
                    heroContent={(<React.Fragment><h2>Kontakt</h2></React.Fragment>)}
                />
                <main>
                    <section class="section">
                        <div className="container">
                            {this.state.sent &&
                                <div class="notification is-success">
                                    <button class="delete" onClick={e => this.setState({ sent: false})} />
                                    Danke, dass Sie uns kontaktiert haben. Ihre Nachricht wurde gesendet.
                                </div>
                            }
                            {this.state.error &&
                                <div class="notification is-danger">
                                    <button class="delete" onClick={e => this.setState({ error: null})} />
                                    {this.state.error}
                                </div>
                            }
                            <form onSubmit={this.handleSubmit}>
                                <div className="field is-horizontal">

                                    <div className="field-label is-normal">
                                        <label className="label">Name</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control has-icons-left">
                                                <input className="input" type="text" placeholder="Name und Familienname" value={name} onChange={e => this.setState({ name: e.target.value})} />
                                                <span className="icon is-small is-left">
                                                    <i className="fa fa-user" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">Email</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control has-icons-left">
                                                <input className="input" type="email" placeholder="Email" value={email} onChange={e => this.setState({ email: e.target.value})} />
                                                <span className="icon is-small is-left">
                                                    <i className="fa fa-envelope" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">Nachricht</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <textarea className="textarea" placeholder="Deine Nachricht" value={message} onChange={e => this.setState({ message: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-horizontal">
                                    <div className="field-label isnormal"/>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                {this.shouldLoadCatpthcha() &&
                                                    <ReCAPTCHA
                                                        style={{ display: "inline-block" }}
                                                        theme="dark"
                                                        sitekey={siteKey}
                                                        onChange={this.handleChange}
                                                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                    /> 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field is-horizontal">
                                    <div className="field-label" />
                                    <div className="field-body">
                                        <div className="control">
                                            <button disabled={this.shouldLoadCatpthcha()} type="submit" className="button is-primary" style={{padding: '0.3em 1.5em'}}>Senden</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </section>
                </main>
            </Grid>

        );
    }
}



export default Contact;
