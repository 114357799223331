import React from 'react';
import styled from 'styled-components';

import Zoom from 'react-reveal/Zoom';

const Wrapper = styled.section`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  h3  {
    margin: 2em 3em 1em 3em;
    font-size: 1.3em;
    text-align: center;
  }

`;

const ContactUs = () => {
  return (
    <Wrapper>
      <Zoom>
        <h3>Unsere Studios bieten eine gemütliche Atmosphäre für all Ihre Bräunungswünsche!</h3>
      </Zoom>
    </Wrapper>
  );
}


export default ContactUs;
