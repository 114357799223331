import React from 'react';
import Fade from 'react-reveal/Fade';

const NewCustomerTeaser = () => {
  return (
    <section className="nc-teaser">
      <div className="container">
        <div className="columns">
          <Fade>
            <div class="column is-9">
              <h3>Festpreis für alle Bräunungssitzungen!</h3>
              <p> bis 20 Minuten € 11,30 alle Solarien, Teilkörper 15 Minuten € 9,60</p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}



export default NewCustomerTeaser;
