import React from 'react';

import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


import office16img from '../../media/offices/16/4-thumb.png'
import office11img from '../../media/offices/11/4-thumb.png'
import office10img from '../../media/offices/10/header.png'
import office22img from '../../media/offices/22/4-thumb.png'



const offices = [
  {
    name: 'Donaustadt',
    img: office22img,
    address: 'Kaisermühlenstraße 24, 1220 Wien',
    email: 'office22@crazysun.at',
    number: '+43 01 967 6420', href: 'offices/grundstrasse'
  },
  {
    name: 'Simmering',
    img: office11img,
    address: 'Simmeringer Hauptstraße 91, 1110 Wien',
    email: 'office11@crazysun.at',
    number: '+43 01 749 9800',
    href: 'offices/grundstrasse'
  },
  {
    name: 'Ottakring',
    img: office16img,
    address: 'Ottakringer Straaße 75, 1160 Wien',
    email: 'office16@crazysun.at',
    number: '+43 01 407 3525',
    href: 'offices/grundstrasse'
  },
  {
    name: 'Favoriten',
    img: office10img,
    address: 'Gudrunstraße 137-139, 1100 Wien',
    email: 'office10@crazysun.at',
    number: '+43 01 603 0159',
    href: 'offices/grundstrasse'
  },
];


const StyledCardLink = styled(Link)`
  &:hover {
    transform: translateY(1em);
  }

  .content {
    z-index: 100;
  }

`;

const OfficeCard = ({
  office
}) => {
  return (
      <div className="card">
        
    <StyledCardLink to={office.href}>

        <div className="card-image">
          <figure className="image is-4by2">
            <img src={office.img} alt={office.name} />
          </figure>
        </div>
    </StyledCardLink>

        <div className="card-content">
        <StyledCardLink to={office.href}>
          <div className="media">
            <div className="media-content">
              <p className="title is-5">{office.name}</p>
            </div>
          </div>
        </StyledCardLink>

          <div className="content">
            <StyledCardLink to={office.href}>
            {office.address} <br/>
            <StyledCardLink to={office.href}>
              {office.email} <br/>
            </StyledCardLink>
            </StyledCardLink>
            <a className="on-top" href={`tel:${office.number}`}>{office.number}</a> <br/>
            <br />
          </div>

        </div>
      </div>
  );
}




const SectionWrapper = styled.section`
  width: inherit;
  padding: 4em;
  display: flex;

  @media (max-width: 950px) {
    flex-wrap: wrap;
    padding: 1em;
  }


  > div {


    &:last-of-type {
      display: grid;
      grid-template-columns: repeat(4, 250px);
      grid-gap: 1em;
      overflow-x: scroll;
    }


    &:first-of-type {
      min-width: 20em;
      flex-grow: 1;
      flex-shrink: 0;
      text-align: right;
      padding: 2em;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h2 {
        font-size: 2em;
      }
      @media (max-width: 950px) {
        width: 100%;
        padding: 1em;
        min-width: 12em;

        h2 {
          font-size: 1.5em;
        }
        p {
          font-size: 0.8em;
        }
      }
    }
  }

`;


const OfficesTeaser = ({ className}) => {
  return (
    <SectionWrapper className="offices-teaser">
      <Fade>
        <div>
          <h2>Unsere Filialen</h2>
          Crazysun, mit bestem Service<br/> in Wien. Immer in Ihrer Nähe
        </div>
      </Fade>
      <div>
        { offices.map(office => (
          <Zoom>
            <OfficeCard office={office} />
          </Zoom>
        ))}
      </div>
    </SectionWrapper>
  );
}

export default OfficesTeaser;
