import React from 'react';
import CookieBanner from 'react-cookie-banner';


import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';


import Logo from '../../media/logo.png';
import Menu from '../../media/menu.svg';
import Footer from './Footer';


const GridWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;


  display: grid;
  grid-template-columns: 250px auto;

`;


const SidebarWrapper = styled.nav`
  background: white;
  margin: 0;
  padding: 0;
  position: relative;
  display:flex;
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
  
  > div {
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    width: inherit;
    padding: 0.5em 1em;
    flex-grow: 1;

    background: #212126;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 150px auto 150px 45px;


    > img {
      margin-top: 50px;
      width: 6em;
      justify-self: center;
      align-self: start;
    }



    ul {
      align-self: center;
      li {
        padding: 0.2em 0;
        text-align: center;
        a {
          color: #EFF0EB;
          font-family: 'Playfair Display', serif !important;
          letter-spacing: 1px;
          text-align: center;
          font-size: 0.9;
        }
      }
    }
    div {
      align-self: end;
      ul {
        li {
          padding: 0.1em 0;
          line-height: 0.9em;
          a {
            color: #a67c00;
            font-size: 0.8em;
            font-family: "Nunito", sans-serif
          }
        }
      }


      &:last-of-type {
        border-top:1px solid #a67c00;
        padding: 0.5em 0;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        a {
          margin-right: 0.2em;
        }

        .mail {
          font-size: 0.7em;
          margin-left: 2.3em;
        }
      }
    }
  }
`;




//       <GridWrapper>
//         <Sidebar />
//         <ContentWrapper>
//           {this.props.children}
// 
//           <Footer />
//         </ContentWrapper>
//       </GridWrapper>

const StyledCookieBanner = styled(CookieBanner)`
  background: #212126;
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  padding: 1em 0;

  .button-close {
    margin-top: 1em;
    background: #D551DA;
    border-color: transparent;
    color: #fff;
    padding: 0.3em;
    border-radius: 4px;
  }
`;


class Grid extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: true
    }
  }

  componentDidMount() {
    this.initialize();
  }
  
  initialize = () => {
    this.setState({ isOpen: false });
  }

  toggleNav = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen}));

  render() {
    return (
      <div className={`canvas ${this.state.isOpen ? 'show-nav': ''}`}>
        <Sidebar className="side-nav" toggleNav={this.toggleNav} />
        <StyledCookieBanner
          cookie="user-has-accepted-cookies"
          disableStyle={true}
          buttonMessage="ICH BIN EINVERSTANDEN"
          message={(
          <div>
            Diese Website verwendet Cookies, um bestimmte Funktionen zu ermöglichen. <br/>
            Mit der Nutzung unserer Seite erklären Sie sich damit einverstanden. Alle details finden Sie in unserer <a href="/datenschutz.pdf" target="_blank">Datenschutzerklärung</a>
          </div> 
          )}
        
        />
        <main className="main-content">
          <a id="hamburger" className="toggle-button" onClick={this.toggleNav}>
            <span />
            <span />
            <span />
          </a>
          {this.props.children}
          <Footer />
        </main>
      </div>
    );
  }
}

const Sidebar = ({className}) => {
  return (
    <SidebarWrapper {...{className}}>
      <div>
        <img src={Logo} />
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">Über Uns</Link></li>
          <li><Link to="/offices">Standorte</Link></li>
          <li><Link to="/products/beds">Produkte</Link></li>
          <li><Link to="/prices">Preise</Link></li>
        </ul>

        <div>
          <ul>

            <li>

              <Link to="/contact">
                <span>Kontakt</span>
              </Link>
            </li>
            <li>

              <Link to="/impressum">
                <span className="is-small">Impressum</span>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <a className="is-facebook" href="https://www.facebook.com/crazysunwien/" target="_blank">
            <span className="icon is-small">
                <i className="fa fa-facebook"></i>
            </span>
          </a>
          <a className="is-instagram" href="https://www.instagram.com/crazy_sun_wien/" target="_blank">
            <span className="icon is-small">
                <i className="fa fa-instagram"></i>
            </span>
          </a>
          <a href="mailto:info@crazysun.at">
            <span className="mail">info@crazysun.at</span>
          </a>
        </div>
      </div>
    </SidebarWrapper>
  );
}

export default withRouter(Grid);