import React from 'react';
import Hero from '../components/Layout/Hero';
import SEO from '../components/SEO';
import Grid from '../components/Layout/Grid';
import Fade from 'react-reveal/Fade';

const Impressum = () => {

    return (
        <Grid>
        <SEO {...{
            title: 'Crazysun Sonnenstudio | Impressum',
            description: `Unsere Studios bieten eine gemütliche Atmosphäre für alle Ihre Bräunungswünsche!`,
        }}/>
            <main>
                <section class="section ">
                <Fade>
                    <div className="container is-fullhd has-text-centered">
                        <p>Informationspflicht laut &sect;5 E-Commerce Gesetz, &sect;14 Unternehmensgesetzbuch, &sect;63 Gewerbeordnung und Offenlegungspflicht laut &sect;25 Mediengesetz.</p>
                        <p>Crazy Solarien KG<br />Gudrunstra&szlig;e 137-139, <br />1100 Wien, <br />&Ouml;sterreich</p>
                        <p>Unternehmensgegenstand: Sonnenstudio <br />UID-Nummer: ATU66447516<br />Firmenbuchnummer: FN507769<br />Firmenbuchgericht: Handelsgericht Wien <br />Firmensitz: Simmeringer Hauptstra&szlig;e 91</p>
                        <p>E-Mail: office@crazy-sun.at</p>
                        <p>Mitglied bei: WKO, Landesinnung, etc.<br />Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at</p>
                        <p>Aufsichtsbeh&ouml;rde/Gewerbebeh&ouml;rde: Bezirkshauptmannschaft Musterhausen<br />Berufsbezeichnung: Solarium/ B&auml;der <br />Verleihungsstaat: &Ouml;sterreich</p>
                        <p>Gesch&auml;ftsf&uuml;hrer<br />Ibrahim K&ouml;se</p>
                        <p>Quelle: Erstellt mit dem Impressum Generator von firmenwebseiten.at in Kooperation mit smartetechnik.at</p>
                        <p>EU-Streitschlichtung<br />Gem&auml;&szlig; Verordnung &uuml;ber Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) m&ouml;chten wir Sie &uuml;ber die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />Verbraucher haben die M&ouml;glichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europ&auml;ischen Kommission unter http://ec.europa.eu/odr?tid=221085808 zu richten. Die daf&uuml;r notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
                        <p>Wir m&ouml;chten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                        <p>Haftung f&uuml;r Inhalte dieser Webseite<br />Wir entwickeln die Inhalte dieser Webseite st&auml;ndig weiter und bem&uuml;hen uns korrekte und aktuelle Informationen bereitzustellen. Leider k&ouml;nnen wir keine Haftung f&uuml;r die Korrektheit aller Inhalte auf dieser Webseite &uuml;bernehmen, speziell f&uuml;r jene die seitens Dritter bereitgestellt wurden.</p>
                        <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>
                        <p>Haftung f&uuml;r Links auf dieser Webseite<br />Unsere Webseite enth&auml;lt Links zu anderen Webseiten f&uuml;r deren Inhalt wir nicht verantwortlich sind. Haftung f&uuml;r verlinkte Websites besteht laut &sect; 17 ECG f&uuml;r uns nicht, da wir keine Kenntnis rechtswidriger T&auml;tigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen w&uuml;rden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
                        <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>
                        <p>Urheberrechtshinweis<br />Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
                        <p>Bildernachweis<br />Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich gesch&uuml;tzt.</p>
                        <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
                        <p>Fotograf Mustermann<br />Datenschutzerkl&auml;rung</p>
                        <p>Datenschutz<br />Wir haben diese Datenschutzerkl&auml;rung (Fassung 04.02.2019-221085808) verfasst, um Ihnen gem&auml;&szlig; der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und dem Datenschutzgesetz (DSG) zu erkl&auml;ren, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsm&ouml;glichkeiten Sie als Besucher dieser Webseite haben.</p>
                        <p>Leider liegt es in der Natur der Sache, dass diese Erkl&auml;rungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bem&uuml;ht die wichtigsten Dinge so einfach und klar wie m&ouml;glich zu beschreiben.</p>
                        <p>Automatische Datenspeicherung<br />Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.</p>
                        <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie</p>
                        <p>die Adresse (URL) der aufgerufenen Webseite<br />Browser und Browserversion<br />das verwendete Betriebssystem<br />die Adresse (URL) der zuvor besuchten Seite (Referrer URL)<br />den Hostname und die IP-Adresse des Ger&auml;ts von welchem aus zugegriffen wird<br />Datum und Uhrzeit<br />in Dateien (Webserver-Logfiles).</p>
                        <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.<br />Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 f DSGVO (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu erm&ouml;glichen.</p>
                        <p>Speicherung pers&ouml;nlicher Daten<br />Pers&ouml;nliche Daten, die Sie uns auf dieser Website elektronisch &uuml;bermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere pers&ouml;nlichen Angaben im Rahmen der &Uuml;bermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
                        <p>Wir nutzen Ihre pers&ouml;nlichen Daten somit nur f&uuml;r die Kommunikation mit jenen Besuchern, die Kontakt ausdr&uuml;cklich w&uuml;nschen und f&uuml;r die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre pers&ouml;nlichen Daten ohne Zustimmung nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
                        <p>Wenn Sie uns pers&ouml;nliche Daten per E-Mail schicken &ndash; somit abseits dieser Webseite &ndash; k&ouml;nnen wir keine sichere &Uuml;bertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschl&uuml;sselt per E-Mail zu &uuml;bermitteln.</p>
                        <p>Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie k&ouml;nnen diesen Einwilligung jederzeit widerrufen &ndash; eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
                        <p>TLS-Verschl&uuml;sselung mit https<br />Wir verwenden https um Daten abh&ouml;rsicher im Internet zu &uuml;bertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im Internet k&ouml;nnen wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Daten&uuml;bertragung am kleinen Schlosssymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>
                        <p>Cookies<br />Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br />Ein Cookie ist ein kurzes Datenpaket, welches zwischen Webbrowser und Webserver ausgetauscht wird, f&uuml;r diese aber v&ouml;llig bedeutungslos ist und erst f&uuml;r die Webanwendung, z. B. einen Online-Shop, eine Bedeutung erh&auml;lt, etwa den Inhalt eines virtuellen Warenkorbes.</p>
                        <p>Es gibt zwei Arten von Cookies: Erstanbieter-Cookies werden von unserer Website erstellt, Drittanbieter-Cookies werden von anderen Websites (z. B. Google Analytics) erstellt.<br />Beispielhafte Cookiedaten:</p>
                        <p>Name: _ga<br />Ablaufzeit: 2 Jahre<br />Verwendung: Unterscheidung der Webseitenbesucher<br />Beispielhafter Wert: GA1.2.1326744211.152221085808<br />Man unterscheidet drei Kategorien von Cookies: unbedingt notwendige Cookies um grundlegende Funktionen der Website sicherzustellen, funktionelle Cookies um die Leistung der Webseite sicherzustellen und zielorientierte Cookies um das Benutzererlebnis zu verbessern.</p>
                        <p>Wir nutzen Cookies, um unsere Webseite nutzerfreundlicher zu gestalten. Einige Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese l&ouml;schen. Sie erm&ouml;glichen es uns, Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen.</p>
                        <p>Cookie Einstellungen sehen und Cookies l&ouml;schen</p>
                        <p>Wenn Sie feststellen m&ouml;chten, welche Cookies in Ihrem Browser gespeichert wurden, Cookie-Einstellungen &auml;ndern oder Cookies l&ouml;schen m&ouml;chten, k&ouml;nnen Sie dies in Ihren Browser-Einstellungen finden:</p>
                        <p>Safari: Verwalten von Cookies und Websitedaten mit Safari<br />Firefox: Cookies l&ouml;schen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben<br />Chrome: Cookies in Chrome l&ouml;schen, aktivieren und verwalten<br />Internet Explorer: L&ouml;schen und Verwalten von Cookies<br />Wenn Sie die Speicherung von Daten in Cookies nicht w&uuml;nschen, so k&ouml;nnen Sie Ihren Browser so einrichten, dass er Sie &uuml;ber das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Sie k&ouml;nnen jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, l&ouml;schen oder Cookies deaktivieren. Die Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies l&ouml;schen Chrome&rdquo; oder &bdquo;Cookies deaktivieren Chrome&ldquo; im Falle eines Chrome Browsers oder tauschen das Wort &bdquo;Chrome&ldquo; gegen den Namen Ihres Browsers, z. B. Edge, Firefox, Safari aus.<br />Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per Browsereinstellung deaktivieren, k&ouml;nnen manche Funktionen und Seiten nicht wie erwartet funktionieren.</p>
                        <p>MailChimp Datenschutzerkl&auml;rung<br />Wir versenden Newsletter mit MailChimp und verwenden auf dieser Webseite Funktionen des Newsletter Dienstes MailChimp der Firma The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA um Newsletter-Anmeldungen zu erfassen.</p>
                        <p>Allgemeine Informationen zu MailChimp</p>
                        <p>Die Rocket Science Group LLC (MailChimp) unterh&auml;lt Online-Plattformen, die es unseren Nutzern erm&ouml;glichen, in Kontakt mit ihren Abonnenten zu bleiben, in erster Linie per E-Mail. Sie erlauben Benutzern, E-Mail-Adressen und andere Informationen zum Abonnentenprofil, wie z. B. Name, physische Adresse und andere demografische Informationen, in die MailChimp Datenbank hochzuladen. Diese Informationen werden verwendet, um E-Mails zu senden und die Verwendung bestimmter anderer MailChimp-Funktionen f&uuml;r diese Benutzer zu erm&ouml;glichen. In &Uuml;bereinstimmung mit den ver&ouml;ffentlichten Datenschutzrichtlinien teilt MailChimp einige Informationen mit Drittanbietern, um die Dienste bereitzustellen und zu unterst&uuml;tzen, die MailChimp den Nutzern anbietet. MailChimp teilt auch einige Informationen mit Werbepartnern von Drittanbietern, um die Bed&uuml;rfnisse und Interessen der Nutzer besser zu verstehen, damit relevantere Inhalte und zielgerichtete Werbung f&uuml;r diese Nutzer und andere Nutzer bereitgestellt werden k&ouml;nnen.</p>
                        <p>Newsletter Anmeldung</p>
                        <p>Wenn Sie auf unserer Webseite f&uuml;r unseren Newsletter anmelden, werden die eingegebenen Daten bei MailChimp gespeichert.</p>
                        <p>L&ouml;schung Ihrer Daten</p>
                        <p>Sie k&ouml;nnen Ihre Zustimmung f&uuml;r den Erhalt unseres Newsletters jederzeit innerhalb der empfangenen E-Mail per Klick auf den Link im unteren Bereich entziehen. Wenn Sie sich mittels Klick auf den Abmeldelink abgemeldet haben, werden Ihre Daten bei MailChimp gel&ouml;scht.</p>
                        <p>Newsletter Auswertung</p>
                        <p>Wenn Sie einen Newsletter &uuml;ber MailChimp erhalten, werden Informationen wie IP-Adresse, Browsertyp und E-Mail-Programm gespeichert um uns Informationen zur Performance unseres Newsletters zu geben. MailChimp kann mittels den in den HTML-E-Mails integrierten Bildern namens Web Beacons (Details finden Sie auf https://kb.mailchimp.com/reports/about-open-tracking) feststellen ob die E-Mail angekommen ist, ob sie ge&ouml;ffnet wurde und ob Links angeklickt wurden. All diese Informationen werden auf den Servern von MailChimp gespeichert, nicht auf dieser Webseite.</p>
                        <p>MailChimp ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework wodurch der korrekte und sichere Datentransfer pers&ouml;nlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG. Mehr &uuml;ber den Einsatz von Cookies bei MailChimp erfahren Sie auf https://mailchimp.com/legal/cookies/, Informationen zum Datenschutz bei MailChimp (Privacy) k&ouml;nnen Sie auf https://mailchimp.com/legal/privacy/ nachlesen.</p>
                        <p>Google Fonts Datenschutzerkl&auml;rung<br />Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Die Verwendung von Google Fonts erfolgt ohne Authentisierung und es werden keine Cookies an die Google Fonts API gesendet. Sollten Sie ein Konto bei Google haben, werden keine Ihrer Google-Kontodaten an Google w&auml;hrend der Nutzung von Google Fonts &uuml;bermittelt. Google erfasst lediglich die Nutzung von CSS und der verwendeten Fonts und speichert diese Daten sicher. Mehr zu diesen und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=221085808.</p>
                        <p>Welche Daten von Google erfasst werden und wof&uuml;r diese Daten verwendet werden, k&ouml;nnen Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.</p>
                        <p>Google Maps Datenschutzerkl&auml;rung<br />Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite.</p>
                        <p>Durch die Nutzung der Funktionen dieser Karte werden Daten an Google &uuml;bertragen. Welche Daten von Google erfasst werden und wof&uuml;r diese Daten verwendet werden, k&ouml;nnen Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.</p>
                        <p>Google Analytics Datenschutzerkl&auml;rung<br />Wir verwenden auf dieser Website Google Analytics der Firma Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) um Besucherdaten statistisch auszuwerten. Dabei verwendet Google Analytics zielorientierte Cookies.</p>
                        <p>Cookies von Google Analytics</p>
                        <p>_ga<br />Ablaufzeit: 2 Jahre<br />Verwendung: Unterscheidung der Webseitenbesucher<br />Beispielhafter Wert: GA1.2.1326744211.152221085808<br />_gid<br />Ablaufzeit: 24 Stunden<br />Verwendung: Unterscheidung der Webseitenbesucher<br />Beispielhafter Wert: GA1.2.1687193234.152221085808<br />gat_gtag_UA<br />Ablaufzeit: 1 Minute<br />Verwendung: Wird zum Drosseln der Anforderungsrate verwendet. Wenn Google Analytics &uuml;ber den Google Tag Manager bereitgestellt wird, erh&auml;lt dieser Cookie den Namen dc_gtm .<br />Beispielhafter Wert: 1<br />N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter https://support.google.com/analytics/answer/6004245?hl=de.</p>
                        <p>Pseudonymisierung</p>
                        <p>Unser Anliegen im Sinne der DSGVO ist die Verbesserung unseres Angebotes und unseres Webauftritts. Da uns die Privatsph&auml;re unserer Nutzer wichtig ist, werden die Nutzerdaten pseudonymisiert. Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des &sect; 96 Abs 3 TKG sowie des Art 6 EU-DSGVO Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.</p>
                        <p>Deaktivierung der Datenerfassung durch Google Analytics</p>
                        <p>Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) k&ouml;nnen Website-Besucher verhindern, dass Google Analytics ihre Daten verwendet.</p>
                        <p>Sie k&ouml;nnen die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de</p>
                        <p>&nbsp;</p>
                        <p>Google Analytics Zusatz zur Datenverarbeitung<br />Wir haben mit Google einen Direktkundenvertrag zur Verwendung von Google Analytics abgeschlossen, indem wir den &ldquo;Zusatz zur Datenverarbeitung&rdquo; in Google Analytics akzeptiert haben.</p>
                        <p>Mehr &uuml;ber den Zusatz zur Datenverarbeitung f&uuml;r Google Analytics finden Sie hier: https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad</p>
                        <p>Google Analytics IP-Anonymisierung<br />Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbeh&ouml;rden einhalten kann, wenn diese eine Speicherung der vollst&auml;ndigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</p>
                        <p>Mehr Informationen zur IP-Anonymisierung finden Sie auf https://support.google.com/analytics/answer/2763052?hl=de.</p>
                        <p>Eingebettete Social Media Elemente Datenschutzerkl&auml;rung<br />Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.<br />Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social Media Dienst &uuml;bertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.<br />Die folgenden Links f&uuml;hren Sie zu den Seiten der jeweiligen Social Media Dienste wo erkl&auml;rt wird, wie diese mit Ihren Daten umgehen:</p>
                        <p>Instagram-Datenschutzrichtlinie: https://help.instagram.com/519522125107875<br />F&uuml;r YouTube gilt die Google Datenschutzerkl&auml;rung: https://policies.google.com/privacy?hl=de<br />Facebook-Datenrichtline: https://www.facebook.com/about/privacy<br />Twitter Datenschutzrichtlinie: https://twitter.com/de/privacy<br />Facebook Datenschutzerkl&auml;rung<br />Wir verwenden auf dieser Webseite Funktionen von Facebook, einem Social Media Network der FIrma Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.<br />Welche Funktionen (Soziale Plug-ins) Facebook bereitstellt, k&ouml;nnen Sie auf https://developers.facebook.com/docs/plugins/ nachlesen.<br />Durch den Besuch unserer Webseite k&ouml;nnen Informationen an Facebook &uuml;bermittelt werden. Wenn Sie &uuml;ber ein Facebook-Konto verf&uuml;gen, kann Facebook diese Daten Ihrem pers&ouml;nlichen Konto zuordnen. Sollten Sie das nicht w&uuml;nschen, melden Sie sich bitte von Facebook ab.<br />Die Datenschutzrichtlinien, welche Informationen Facebook sammelt und wie sie diese verwenden finden Sie auf https://www.facebook.com/policy.php.</p>
                        <p>Instagram Datenschutzerkl&auml;rung<br />Wir verwenden auf unserer Webseite Funktionen des Social Media Netzwerks Instagram der Firma Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA.</p>
                        <p>Mit den Funktionen zum Einbetten von Instagram-Inhalten (Embed-Funktion) k&ouml;nnen wir Bilder und Videos anzeigen.</p>
                        <p>Durch den Aufruf von Seiten die solche Funktionen nutzen werden Daten (IP-Adresse, Browserdaten, Datum, Uhrzeit, Cookies) an Instagram &uuml;bermittelt, gespeichert und ausgewertet.</p>
                        <p>Sollten Sie ein Instagram-Konto haben und angemeldet sein, werden diese Daten Ihrem pers&ouml;nlichen Konto und den darin gespeicherten Daten zugeordnet.</p>
                        <p>Die Datenschutzrichtlinien, welche Informationen Instagram sammelt und wie sie diese verwenden finden Sie auf https://help.instagram.com/519522125107875.</p>
                        <p>Quelle: Erstellt mit dem Datenschutz Generator von firmenwebseiten.at in Kooperation mit genussblogger.at</p>

                    </div>
                </Fade>
                </section>
            </main>
        </Grid>
    )
}


export default Impressum;
