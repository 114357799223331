import React from 'react';


import styled from 'styled-components';
import Hero from '../../components/Layout/Hero';


//#region kosmetik images

import SexyDatingLegs from '../../media/products/sexy-dating-legs.png';
import BelugaTanPreparer from '../../media/products/beluga-tan-preparer-tube.png';
import BelugaLuxuryBronzer from '../../media/products/beluga-luxury-bronzer.jpg';
import Gold from '../../media/products/gold.jpg';
import XtraBlack from '../../media/products/xtra-black.jpg';
import XtraBrown from '../../media/products/xtra-brown.jpg';
import FaceWax from '../../media/products/face-wax.jpg';
import TanningGlasses from '../../media/products/tanning-glasses.png';

//#endregion
//#region bed images
import E950 from '../../media/products/beds/950.jpg';
import E880 from '../../media/products/beds/880.jpg';
import E750 from '../../media/products/beds/750.jpg';
import E550 from '../../media/products/beds/550.jpeg';
import P1000 from '../../media/products/beds/p100.jpg';
import M7900 from '../../media/products/beds/7900.png';
import IQ from '../../media/products/beds/iq.jpg';
import ProductCard from '../../components/ProductCard';
import SEO from '../../components/SEO';
import Grid from '../../components/Layout/Grid';
//#endregion





const tabItems = [
  { 
    name: 'ERGOLINE BETTEN',
    id: 'beds',
    href: 'products/beds'
  },
  { 
    name: 'SOLARIUM KOSMETIK',
    id: 'solarium-cosmetics',
    href: 'products/solarium-cosmetics'
  },
  { 
    name: 'SOLARIUM HAUTPFLEGE',
    id: 'solarium-skin-care',
    href: 'products/solarium-skin-care',
  },
  { 
    name: 'KÖRPERPFLEGE PRODUKTE',
    id: 'skincare-products',
    href: 'products/skincare-products'
  },
];


const products = {
  'beds': [
    {
      name: 'Ergoline Prestige 1000',
      description: `Mit Intelligent Performance wird ein neuer Standard gesetzt : Die rundum gleichmäßige und bis zu 30% intensivere Bräune wird Sie begeistern. Die faszinierende LED Light Show zeigt bereits auf den ersten Blick, dass wir Ihnen mit der Prestige 1000 etwas ganz besonderes bieten.`,
      image: P1000
    },
    {
      name: 'Ergoline 950',
      description: 'Die neue Ergoline 950 bietet zeitgemäße Solarientechnik. (zu einem unschlagbaren Preis – ist der Preis wichtig für die Kunden, verkauft ihr das Gerät auch? - sonst weglassen). Ihre markante Linienführung, aufmerksamkeitsstarke LED Light Show und ein hell erleuchteter Bräunungstunnel laden zum Bräunen ein! Ein weiteres Highlight werden Sie bei der Besonnung erleben: das intuitive und komfortable Glasbedienfeld mit großem Display macht die Bedienung komfortabler denn je. Einfach die gewünschte Funktion anwählen und mit Plus und Minus einstellen. Übrigens: im Smart Control-Bedienfeld lassen sich bereits vor der Besonnung die gewünschten Komfortmerkmale einstellen. Im Gesichtsbereich garantiert die Ultra Performance Plus-Technolgie mit 3 Gesichtsbräunern und 2 zusätzlichen UVB-B-Spaghettis 0,3-optimierte Bräunungsleistung.',
      image: E950
    },
    {
      name: 'Ergoline 880',
      description: `Zarte Aromen und feiner Sprühnebel entspannen oder vitalisieren Körper und Geist je nach Wunsch und Einstellung. Für eine angenehme Konditionierung während der Bräunung sorgen eine separate Liegeflächenkühlung, eine 2-Kreis-Köperlüftung und natürlich die standardmäßige Gesichtslüftung.`,
      image: E880
    },
    {
      name: 'Ergoline IQ',
      description: '51 UV-Lampen à 120-180 Watt im Körper-Bereich und 4 Gesichts-Bräuner à 260-520 Watt: Ergoline IQ verfügt serienmäßig über ein Intelligent Power System für variable UV-Power. Damit bietet sie individuelle Bräune von Kopf bis Fuß. Komfort in Serie: Ergoline IQ ist komplett ausgestattet mit Balanced Tan, Aqua Fresh & Aroma, Vibra Sound, Voice Guide IQ, Climatronic IQ & IR-Function.',
      image: IQ
    },
    {
      name: 'Ergoline Esprit 750',
      description: 'Die Ergoline Esprit 750 Dynamic Power strahlt mit ihren markanten Linien und leuchtenden Stimmungslichtern eine kühle blaue Harmonie aus. Die 48 Dynamic Power 200-Watt-UV-Lampen, 4 Ultra Performance-Gesichtsbräuner und verfügbare Hochdruck-Schulterbräuner verleihen Ihnen eine perfekt coole Bräune. Für Luxus sorgen die Temptronic-Klimatisierung, Aqua Mist & Aroma sowie das breite Multi Relax-Basisacryl für ultimativen kühlen und erfrischenden Komfort. Mit dem Bedienfeld der Ergoline Esprit 750 haben Sie alles unter Kontrolle. Die beleuchteten Symbole, die Volltextanzeige und die verfügbare Sprachführung erleichtern das Einstellen jeder Funktion. Und als Krönung bietet ein verfügbares 3D-Sound-System mit MP3-Player-Docking-In und SD-Kartensteckplatz puren Hörgenuss, der für jeden individuell angepasst werden kann...',
      image: E750
    },

    {
      name: 'Ergoline Open Sun',
      description: `Die Open Sun macht eine Aussage mit 30 Hochdruckeinheiten perfekt entlang der Kappe und der Basis ausgerichtet sind. Mit seiner überlegenen Glasreflektortechnologie und seinem Filterglas-Know-how demonstriert dieses Ergoline-Flaggschiff den anspruchsvollsten Gerber, wie kraftvoll und gleichzeitig kühl und luftig die Hochdruckbräunung sein kann. Komfortfunktionen wie das Multi Relax-Acryl, ein Stereo-Sound-Paket mit externem MP3-Player-Anschluss und die leistungsstarke Fan Cooling Plus-Funktion sorgen für ein schnelles Bräunen. Die enorme Leistung und Funktionen des Open Sun 1050 werden von einem Panel mit 32-Feld-LED-Anzeige gesteuert. Alle Bedienelemente sind für den Bräunungs-Client leicht erreichbar und mit einfachen Bildsymbolen gekennzeichnet.`,
      image: E550
    },
    {
      name: 'Mega Sun 7900',
      description: 'Die alpha Serie der Mega Sun 7900 Solarien beeindruckt durch ihre herausragenden Features und ihr außergewöhnliches Design. Hier wird die Besonnung auf der Sonnenbank zum unvergesslichen Rendezvous. Hier trifft Perfektion auf Leistung – erleben Sie den Unterschied. Das Solarium Mega Sun 7900 vereint einzigartige Größe, optimale Leistung, größtmöglichen Komfort und Features der besonderen Art. Über das bedienerfreundliche Display preSelection, das außen am Oberteil des Solariums zu bedienen ist, können diverse Wohlfühleinstellungen bereits vor der Besonnung bequem und einfach vorgenommen werden. Das optionale, intelligente Besonnungssystem intelliSun von megaSun stellt sich perfekt auf die hohen Ansprüche und Wünsche der Sonnenstudio-Kunden ein und vereint drei unterschiedliche Besonnungsvarianten. Von Basic über Mediterran bis hin zu Caribic, besonnt sich jeder Kunde mit einem UV-Spektrum, das auf seine individuellen Wünsche abgestimmt ist. Die Mega Sun 7900 verleiht Ihnen ein Gefühl von Exklusivität und Besonderheit. ',
      image: M7900
    }

  ],
  'solarium-cosmetics': [

    {
      name: 'Beluga Luxury Tan Preparer',
      description: 'Exklusive Pflege und Vorbereitung aller Bräunungsprozesse mit TANNYMAXX BELUGA LUXURY TAN PREPARER. Die sorgfältig abgestimmten Inhaltsstoffe des Beluga Tan Preparer bereiten Ihre Haut nach neuesten Erkenntnissen bestmöglich auf alle Bräunungsprozesse vor. Bräunung von Gesicht und Körper werden über die im Kaviar-Extrakt enthaltenen Aminosäuren Lysin, Arginin und Histidin im Zellstoffwechsel unterstützt. Aloe Vera verwöhnt Ihre Haut mit natürlicher Feuchtigkeit und schützt sie vor Negativen Einflüssen.',
      image: BelugaTanPreparer
    },
    {
      name: 'Xtra super brown chocolate',
      description: 'Die Classic Xtra Super Coconut Chocolate Tanning Milk verführt die Sinne und den Körper mit ihrem süßen Kokos-Schokoladen-Duft. Ihre pflegende Wirkstoffbasis aus Aloe Vera unterstützt die Haut mit Feuchtigkeit, hilft der Haut sich zu regenerieren und unterstützt die Verstärkung der hauteigenen Lipidbarriere. Der integrierte Kokosnussextrakt unterstützt den Schutz der Haut durch das enthaltene Vitamin C, fördert den Stoffwechsel und unterstützt ein schönes und pralles Aussehen. ',
      image: XtraBrown
    },
    {
      name: 'Xtra BLACK POWER BUTTER',
      description: 'Reichaltige Creme für trockene Haut mit wertvoller Shea-Butter und edler Kakaobutter, sowie Aloe Vera und Q 10. Die Inhaltsstoffe pflegen die Haut und machen diese samtweich.',
      image: XtraBlack
    },
    {
      name: 'Sexy Dating Legs',
      description: 'Das Spezialprodukt für Bräunung und Straffung der Beine. Einmal als Lotion mit Selbstbräuner, einmal als Lotion mit Selbstbräuner und Tingle-Effekt. Aloe Vera und Shea-Butter sorgen für samtweiche Haut, Guarana und Koffein beleben und straffen.',
      image: SexyDatingLegs
    },
    {
      name: 'Face Wax',
      description: 'Ein immer wieder beliebtes Brännungsprodukt für das Gesicht wie Dekolleté.',
      image: FaceWax
    },
    {
      name: 'UV-Brille',
      description: 'Für sorgenfreies Sonnenvergnügen ohne unschöne Augenränder. Ein zuverlässiger UV-Schutz für die Augen ist garantiert. Sie absorbiert vollständig die für die Augen schädliche UV-Strahlung. Die UV-Brille kann mit oder ohne Gummiband getragen werden. In jeder kleinen Tasche zu verstauen. Ideal auch im Sommer, für Urlaub am Strand.',
      image: TanningGlasses
    }
  ],
  'solarium-skin-care': [
    {
      name: 'Beluga Luxury Bronzer',
      description: 'Ausgewogener DHA-Anteil für ein Braun, das zu Ihnen passt. TANNYMAXX BELUGE LUXURY BRONZER steht für ein sinnliches, natürliches Braun, perfekte Gleichmäßigkeit und luxuriöse Pflege in einem Hybrid-Produkt, das alle Vorzüge hochwertiger Inhaltsstoffe vereint. Kein Zweifel, mit dem Beluga Luxury Bronzer machen Sie alles richtig. Ihre Braun wirkt natürlich, gleichmäßig und gepflegt. ',
      image: BelugaLuxuryBronzer
    },
    {
      name: 'Tanymaxx GOLD 999,99 FACE CARE',
      description: 'Sie genießen es Blicke zu fangen? Mit der Tanymaxx Gold Face Tanning Lotion haben Sie ab sofort eine feine Freundin, die sich hingebungsvoll um alle Bedürfnisse und Wünsche ihres Gesichts kümmert. Mit sensitivem Gespür setzt sie genau dort an, wo Ihre sensible Gesichtshaut Schutz und Pflege vor, während und nach dem Sonnen benötigt.',
      image: Gold
    },
    {
      name: 'Sexy Dating Legs',
      description: 'Das Spezialprodukt für Bräunung und Straffung der Beine. Einmal als Lotion mit Selbstbräuner, einmal als Lotion mit Selbstbräuner und Tingle-Effekt. Aloe Vera und Shea-Butter sorgen für samtweiche Haut, Guarana und Koffein beleben und straffen.',
      image: SexyDatingLegs
    },
    {
      name: 'Face Wax',
      description:'Ein immer wieder beliebtes Brännungsprodukt für das Gesicht wie Dekolleté.',
      image: FaceWax
    },
  ],
  'skincare-products': [


    {
      name: 'Sexy Dating Legs',
      description: 'Das Spezialprodukt für Bräunung und Straffung der Beine. Einmal als Lotion mit Selbstbräuner, einmal als Lotion mit Selbstbräuner und Tingle-Effekt. Aloe Vera und Shea-Butter sorgen für samtweiche Haut, Guarana und Koffein beleben und straffen.',
      image: SexyDatingLegs
    },
    {
      name: 'Face Wax',
      description:'Ein immer wieder beliebtes Brännungsprodukt für das Gesicht wie Dekolleté.',
      image: FaceWax
    },
    {
      name: 'Tanymaxx GOLD 999,99 FACE CARE',
      description: `Sie genießen es blicke zu fangen?
        Mit der Tanymaxx Gold Face Tanning Lotion haben sie ab sofort
        eine feine Freundin, die sich hingebunsvoll um alle Bedürfnisse
        und Wünsche ihres Gesichts kümmert. Mit sensitivem Gespür
        seztz sie genau dort an, wo ihre sensible Gesichtshaut Schutz und
        Pflege vor, während und nach dem Sonnen benötigt.`,
      image: Gold
    },
  ]
};

//#region Styled Components
export const StyledProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;


`;

const StyledBedsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100% !important;
  }
`;



//#endregion

class Product extends React.Component {


  constructor() {
    super();

    this.state = {
      current: undefined
    }
  }

  setCurrent = (props) => {
    const { match } =  props;
    this.setState({
      current: tabItems.find(i => `/${i.href}` === match.url),
    });
  }

  componentWillReceiveProps = (nextProps)  => this.setCurrent(nextProps);
  componentDidMount = ()  => this.setCurrent(this.props);

  render() {

    const { current } = this.state;
    const { match } = this.props;

    if (!current) return null;

    const productCards = products[current.id]
      .map((product, i) => (
        <ProductCard
          name={product.name} 
          description={product.description}
          image={product.image}
          index={i}
        />
      )
    );

    return (
      <Grid>
        <SEO {...{
          title: `Crazysun Sonnenstudio | Produkte | ${current.name}`,
          description: products[current.id][0].description,
        }}/>
        <Hero
          match={match}
          bgClass={match.params.productId}
          tabItems={tabItems} 
          heroContent={(
            <h2><br />{current.name}</h2>
          )}
        />
        <main>
          <section className="section video-teaser-wrapper">
            <div className="container"> 
              {current.id !== 'beds' 
                ? <StyledProductsWrapper>{productCards}</StyledProductsWrapper> 
                : <StyledBedsWrapper>{productCards}</StyledBedsWrapper> 
              }
            </div>
          </section>
        </main>
      </Grid>
    )




  }



}


export default Product;
