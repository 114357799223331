

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';



class SEO extends React.Component {



  render() {
    const title = this.props.title || 'Crazy Sun';
    const description = this.props.description || 'DAS BESTE BRÄUNUNGSANLAGE  IM HERZEN DES WIENS!';

    console.log(this.props);

    return (

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`www.crazysun.at${this.props.location.pathname}`} />

      </Helmet>
  )
  }
}


export default withRouter(SEO);