import React from 'react';
import Zoom from 'react-reveal/Zoom';
import styled from 'styled-components';


const StyledProductCard = styled.div`
  padding: 0.5em 1em;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
    padding: 0.5em 0;

  }

  &:nth-of-type(odd) {
    text-align: right;
    > .card {
      > div {
        > div {
          float: right;
        }
      }
    }
  }

  > .card {
    display: flex;
    flex-wrap: nowrap;
    align-self: stretch;


    > div {
      width: 60%;
      padding: 2em;

      &.image-div {
        padding: 0;
        width: 40%;
        content: ' ';
        align-self: stretch;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      > div {
        &:first-of-type {
          content: ' ';
          width: 3em;
          height: 2px;
          background: #D0D1CD;
        }
        &:last-of-type {
          content: ' ';
          width: 3em;
          height: 2px;
          background: #D0D1CD;
        }
      }

      h3 {
        font-size: 1.4em;
        text-transform: capitalize;
        padding: 0.5em 0;
      }

      p {
        font-size: 0.8em;
      }


      @media (max-width: 960px) {

        width: 100% !important;

        &.image-div {
          height: 200px;
          width: 100%;
        }

      }

    }
    @media (max-width: 960px) {
      flex-wrap: wrap;
      flex-direction: column;

    }
  }
`;


const ProductCard = ({ name, description, image, index }) => (
  <StyledProductCard key={`product-${index}`}>
    <Zoom>

      <div className="card">
        {index %2 !== 0 && <div className="image-div" style={{backgroundImage: `url(${image})`}} /> }
        <div>
          <div />
          <h3>{name}</h3>
          <p>{description}</p>
          <div />
        </div>
        {index %2 === 0 && <div className="image-div" style={{backgroundImage: `url(${image})`}} /> }
      </div>
    </Zoom>
  </StyledProductCard>

)


export default ProductCard;