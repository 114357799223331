import React, { Fragment } from 'react';


import { Link } from "react-router-dom";

const Hero = ({ className, tabItems, bgClass, heroContent, match}) => {
  let navbarRef = undefined;
//     <header>
//     <section className="top-bar is-primary is-hidden-mobile">
//       <div className="container">
//         <nav className="level">
//           <div className="level-left">
//             <div className="level-item header-top-item">
//               <a href="mailto:info@crazysun.at">
//                 <span className="no-span-up">info@crazysun.at</span>
//               </a>
//             </div>
//           </div>
// 
//           <div className="level-right has-text-left-mobile">
//             <div className="level-item header-top-item">
//               <Link to="/contact">
//                 <span>Kontakt</span>
//               </Link>
//             </div>
//             <div className="level-item header-top-item">
//               <Link to="/impressum">
//                 <span className="is-small">Impressum</span>
//               </Link>
//             </div>
//             <div className="level-item">
//               <a className="is-facebook" href="https://www.facebook.com/crazysunwien/" target="_blank">
//                 <span className="icon is-small">
//                     <i className="fa fa-facebook"></i>
//                 </span>
//               </a>
//             </div>
//             <div className="level-item">
//               <a className="is-instagram" href="https://www.instagram.com/crazy_sun_wien/" target="_blank">
//                 <span className="icon is-small">
//                     <i className="fa fa-instagram"></i>
//                 </span>
//               </a>
//             </div>
//           </div>
//         </nav>
//       </div>
//     </section>
// 
//       <nav className="navbar is-dark">
//         <div className="container">
//           <div className="navbar-brand">
//             <Link to="/" className="navbar-item logo">
//               CRAZYSUN
//             </Link>
//             <span 
//               className="navbar-burger burger" 
//               data-target="navbarMenuHeroA"
//               onClick={(e) => {
//                 navbarRef.classList.toggle('is-active');
//                 e.target.classList.toggle('is-active');
//               }}
//             >
//               <span></span>
//               <span></span>
//               <span></span>
//             </span>
//           </div>
//           <div ref={(ref) => navbarRef = ref} id="navbarMenuHeroA" className="navbar-menu">
//             <div className="navbar-end">
//               <Link to="/" className="navbar-item">
//                 Home
//               </Link>
//               <Link to="/about" className="navbar-item">
//                 Über Uns
//               </Link>
//               <Link to="/offices" className="navbar-item">
//                  Standorte
//               </Link>
//               <Link to="/products/beds" className="navbar-item">
//                  Produkte
//               </Link>
//               <Link to="/prices" className="navbar-item">
//                  Preise
//               </Link>
//             </div>
//           </div>
//         </div>
//       </nav>
// 
// 
//     </header>
return (
  <Fragment>

    <section 
      className={
        `hero is-primary ${heroContent || tabItems ? (className ? className :'is-small') : 'is-fullheight'} ${bgClass !== undefined ? bgClass : ''}`
        }>
      {bgClass !== undefined && <div className="layered" />}
      <div className="hero-body">
        <div className="container has-text-centered heroCont">
          {heroContent ? heroContent : 
            <Fragment>
              <h1 className="title">
                Title
              </h1>
              <h2 className="subtitle">
                Subtitle
              </h2>
            </Fragment> 
          }
        </div>
      </div>

      <div className="hero-foot">
        {
          tabItems !== undefined && 
          <nav className="tabs is-right is-boxed">
              <ul>
                {tabItems.map((item) => (
                    <li key={item.href}>
                      <Link
                        to={`/${item.href}`}
                        className={
                          `subnav-item ${match && match.url === `/${item.href}` ? 'is-active' : ''}`
                        }
                      >{item.name.toLowerCase()}</Link></li>
                ))}
              </ul>
          </nav>
        }
      </div>
    </section>
  </Fragment>
);
}

export default Hero;
