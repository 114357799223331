import React from 'react';


import { Link } from 'react-router-dom';

import { DefaultPlayer}  from 'react-html5video';
import 'react-html5video/dist/styles.css';

import video from '../../media/video.mp4';

import styled from 'styled-components';
import Zoom from 'react-reveal/Zoom';


const VideoWrapper = styled.div`
  padding: 0;
  position: absolute;
  margin-top: -9em;
  box-shadow: -6px 3px 34px 6px rgba(0,0,0,0.75);
  border-radius: 4px;



  @media (max-width: 768px) {
    margin-top: 0;
    position:relative;

  }


  .video-play-icon {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
  }
`;


const StyledVideoWrapper = styled.section`


  width: 100%;
  padding: 5em 0;


  p {
    font-size: 0.9em;
  }



`;


const VideoTeaser = () => {


  return (
    <StyledVideoWrapper>
      <div className="container">
        <div className="columns">
          <div className="column is-6">
            <h2>
              Premiere Tanning seit 15 Jahren!
            </h2>
            <p>
              Crazysun spezialisiert sich auf den Kundenservice bis ins kleinste Detail. Wir bieten modernste Ergoline-Geräte in einer sauberen und komfortablen Atmosphäre  an. <br />
              Modernes Ambiente, geschultes Fachpersonal sowie interessante Angebote sind bei uns ebenfalls zu finden. <br/>
              Schließen Sie die Augen und genießen Sie Ihren Kurzurlaub in der Sonne!<br/>
              Wir freuen uns auf Ihren Besuch. 
            </p>
            <br />
            <Link to="/about" className="button is-primary is-medium">Mehr Erfahren!</Link>
          </div>
          <div className="column is-6">
            <Zoom>
              <VideoWrapper>
                <DefaultPlayer autoPlay loop muted controls={['PlayPause', 'Time', 'Volume']}>
                    <source src={video} type="video/mp4" />
                </DefaultPlayer>
              </VideoWrapper>
            </Zoom>
          </div>
        
        </div>
      </div>
    </StyledVideoWrapper>

  );
};




export default VideoTeaser;


