import React from 'react';
import Hero from '../../components/Layout/Hero';
import ImageGallery from '../../components/imageGallery';


import styled from 'styled-components';



//#region 22

import I221 from '../../media/offices/22/22-000.png';
import I222 from '../../media/offices/22/22-010.png';
import I223 from '../../media/offices/22/22-008.png';
import I224 from '../../media/offices/22/22-007.png';
import I225 from '../../media/offices/22/22-004.png';
import I226 from '../../media/offices/22/22-009.png';


//endregion


import I161 from '../../media/offices/16/22-004.png';
import I162 from '../../media/offices/16/22-000.png';
import I163 from '../../media/offices/16/22-009.png';
import I164 from '../../media/offices/16/22-013.png';
import I165 from '../../media/offices/16/22-016.png';
import I166 from '../../media/offices/16/22-001.png';

import I111 from '../../media/offices/11/22-004.png';
import I112 from '../../media/offices/11/22-000.png';
import I113 from '../../media/offices/11/22-002.png';
import I114 from '../../media/offices/11/22-016.png';
import I115 from '../../media/offices/11/22-015.png';
import I116 from '../../media/offices/11/22-010.png';
import SEO from '../../components/SEO';
import Grid from '../../components/Layout/Grid';
import HomeImageGallery from '../../components/TeaserSections/HomeImageGallery';


const iframeProps = {
  width:"100%",
  height:"600",
  frameborder:"0",
  style:{border: 0},
allowfullscreen:true
};

const tabItems = [
  {
    name: 'GUDRUNSTRASSE 137-139',
    description: '',
    title: 'Gudrunstraße 137-139',
    href: "offices/grundstrasse",
    address: 'Gudrunstraße 137-139, 1100 Wien',
    email: 'office10@crazysun.at',
    number: '+43 01 603 0159',
    id: "grundstrasse",
    map: (
        <iframe
            {...{
              ...iframeProps,
              src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42549.50492619703!2d16.311367928932942!3d48.1999885000099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07e0caf7e7b3%3A0x73c15b8dc557d958!2sCrazy+Sun!5e0!3m2!1str!2sat!4v1549654324954",
              height: 300
            }}
        />

    ),
    tour: (
        <iframe
            {...{
              ...iframeProps,
              src: "https://www.google.com/maps/embed?pb=!4v1547924315238!6m8!1m7!1sTQkdFGfc7tj8xC1Yiy1OQw!2m2!1d48.17792783202693!2d16.37807714880684!3f196.85223!4f0!5f0.7820865974627469"
            }}
        />
    ),
    images: [
      {src: I111, width: 4, height: 3},
      {src: I224, width: 2, height: 3},
      {src: I163 , width: 2, height: 3},
      {src: I114, width: 2, height: 3},
      {src: I115, width: 4, height: 3},
      {src: I223, width: 2, height: 3},
    ]
  },
  {
    name: 'SIMMERINGER HAUPTSTRAßE 91',
    title: 'Simmeringer Hauptstraße 91',
    description: '',
    href: "offices/simmering",
    address: 'Simmeringer Hauptstraße 91, 1110 Wien',
    email: 'office11@crazysun.at',
    number: '+43 01 749 9800',
    id: "simmerring",
    map: (
        <iframe
            {...{
              ...iframeProps,
              height: 300,
              src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42549.50492619703!2d16.311367928932942!3d48.1999885000099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da9f8fcb794fb%3A0xa3390e0d12269c01!2sCrazy+Sun!5e0!3m2!1str!2sat!4v1549654274464"
            }}
        />
    ),
    tour: (
        <iframe
            {...{
              ...iframeProps,
              src: "https://www.google.com/maps/embed?pb=!4v1547924409382!6m8!1m7!1s7dx8g1gtzlmwqFZ7-wbYNg!2m2!1d48.17659603794303!2d16.41404697808128!3f51.805458!4f0!5f0.7820865974627469"
            }}
        />
    ),
    images: [
      {src: I111, width: 4, height: 3},
      {src: I224, width: 2, height: 3},
      {src: I163 , width: 2, height: 3},
      {src: I114, width: 2, height: 3},
      {src: I115, width: 4, height: 3},
      {src: I223, width: 2, height: 3},
    ]
  },
  {
    name: 'OTTAKRINGER STRASSE 75',
    title: 'Ottakringer Straße 75',
    href: "offices/ottakringer",
    address: 'Ottakringer Straße 75, 1160 Wien',
    email: 'office16@crazysun.at',
    number: '+43 01 407 3525',
    description: '',
    id: "ottakringer",
    map: (
        <iframe
            {...{
              ...iframeProps,
              height: 300,
              src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42549.50492619703!2d16.311367928932942!3d48.1999885000099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07e10854a34b%3A0x87a0d41d469a3e5f!2sCrazy+Sun!5e0!3m2!1str!2sat!4v1549654228007"
            }}
        />
    ),
    tour: (
        <iframe
            {...{
              ...iframeProps,
              src: "https://www.google.com/maps/embed?pb=!4v1547921938196!6m8!1m7!1sCAoSLEFGMVFpcE1DUGxjRTJYREtacU1OVVlfc055cVZibWJtQVI4ZmVody1zeHZN!2m2!1d48.21341650782168!2d16.32772944867611!3f181.5!4f0!5f0.7820865974627469"
            }}
        />
    ),
    images: [
      {src: I166, width: 4, height: 3},
      {src: I163, width: 2, height: 3},
      {src: I165, width: 2, height: 3},
      {src: I162, width: 5, height: 5},
      {src: I161, width: 5, height: 3},
      {src: I164, width: 5, height: 3},
    ]

  },
  {
    name: 'KAISERMÜHLENSTRASSE 24',
    description: '',
    title: 'Kaisermühlenstraße 24 ',
    href: "offices/kaisermuehlen",
    address: 'Kaisermühlenstraße 24, 1220 Wien',
    email: 'office22@crazysun.at',
    number: '+43 01 967 6420',
    id: "kaisermuehlen",
    map: (
        <iframe
            {...{
              ...iframeProps,
              height: 300,
              src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42549.50492619704!2d16.31136792893295!3d48.19998850000989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d00d5e6f8c3c5%3A0xed6adbd526050e3c!2sCrazy+Sun!5e0!3m2!1str!2sat!4v1549653894301"
            }}
        />
    ),
    tour: (
        <iframe
            {...{
              ...iframeProps,
              src: "https://www.google.com/maps/embed?pb=!4v1547924485183!6m8!1m7!1sCAoSLEFGMVFpcFBuOGQ3N1ZtVWx1ZE1HVWtYa3hMNGNmc3BXY2I5R1dReU9KZWJO!2m2!1d48.21913066948938!2d16.44946351647377!3f136.5!4f0!5f0.7820865974627469"
            }}
        />
    ),
    images: [
      {src: I226, width: 4, height: 3},
      {src: I223, width: 2, height: 3},
      {src: I225, width: 2, height: 3},
      {src: I221, width: 5, height: 3},
      {src: I224, width: 4, height: 5},
    ]
  },
]

const StyledTourWrapper = styled.div`

  pointer-events:${({focused}) => focused ? 'auto': 'auto'};


`;


class Office extends React.Component {


  constructor() {
    super();
    this.state = {
      isTourFocused: false,
      current: undefined
    }

  }

  setCurrent = (props) => {
    const { match } =  props;
    this.setState({
      current: tabItems.find(i => `/${i.href}` === match.url),
    });
  }

  componentWillReceiveProps = (nextProps)  => this.setCurrent(nextProps);
  componentDidMount = ()  => this.setCurrent(this.props);

  render() {
    const {current } = this.state;
    const { match } = this.props;
    if (!current) return null;
    return (

      <Grid>

        <SEO {...{
          title: `Crazysun Sonnenstudio | Standorte | ${current.name}`,
          description: current.description,
        }}/>
 
        <Hero 
          match={match} 
          bgClass={match.params.officeId} 
          tabItems={tabItems} 
          heroContent={(
            <h2> <br />{current.title}</h2>
          )}
        />

        <main>

          <section class="section video-teaser-wrapper">
            <div className="container">
              <br/>
              <HomeImageGallery photos={current.images || undefined} />
              {current.tour || null}
              <br/>
            </div>
          </section>
          <section className="section video-teaser-wrapper">
            <div className="container">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title is-3 is-capitalized">{current.name}</h1>
                  <h3>{current.description}</h3>
                  <br/>
                  <p><b>Adresse: </b>{current.address}<br/>
                  <b>Email: </b>{current.email}<br/>
                  <b>Telefonnummer: </b><a href={`tel:${current.number}`}>{current.number}</a></p>
                </div>
                <div className="column is-half">{current.map}</div>
              </div>
            </div>
          </section>

        </main>
      </Grid>
    );
  }

}


export default Office;
