import React from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

import I111 from '../media/offices/11/22-004.png';
import I112 from '../media/offices/16/22-000.png';
import I113 from '../media/offices/22/22-007.png';
import I114 from '../media/offices/11/22-016.png';
import I115 from '../media/offices/11/22-015.png';
import I165 from '../media/offices/16/22-016.png';



const photos = [
      { src: I111, width: 4, height: 3 },
      { src: I112, width: 2, height: 3 },
      { src: I113, width: 2, height: 3 },
      { src: I114, width: 2, height: 3 },
      { src: I115, width: 4, height: 3 },
      {src: I165, width: 2, height: 3},
];



class ImageGallery extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    return (
      <div>
        <Gallery photos={this.props.images || photos} direction="row" onClick={this.openLightbox} />
        <Lightbox images={this.props.images || photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>
    )
  }
}


export default ImageGallery;
