import React, { Fragment, Component } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/Layout/Footer';

import {  HashRouter as Router, Route, Link } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Offices from './pages/Offices';
import Office from './pages/Offices/Office';

import Product from './pages/Products/Product';
import Prices from './pages/Prices';
import Impressum from './pages/impressum';
import Contact from './pages/Contact';
import SEO from './components/SEO';
import HomeSecond from './pages/Home2';
import Application from './pages/Jobs';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Crazysun Sonnenstudio</title>
          <meta name="description" content="DAS BESTE BRÄUNUNGSANLAGE IM HERZEN DES WIENS!" />
        </Helmet>

        <Router>
          <div className="App">
            <Route path="/" exact component={Home} />
            <Route path="/about/" component={About} />
            <Route path="/offices/:officeId" component={Office}/>
            <Route exact path="/offices/" component={Offices} />
            <Route path="/products/:productId" component={Product}/>
            <Route path="/prices/" component={Prices} />
            <Route path="/impressum/" component={Impressum} />
            <Route path="/contact/" component={Contact} />
            <Route path="/bewerbung/" component={Application} />
          </div>
        </Router>
      </Fragment>
    );
  }
}

export default App;
