import React from 'react';
import Hero from '../components/Layout/Hero';
import ContactUs from '../components/TeaserSections/ContactUs';
import SEO from '../components/SEO';
import Grid from '../components/Layout/Grid';
import Zoom from 'react-reveal/Zoom';



const Prices = () => {

  return (
    <Grid>
      <SEO {...{
        title: 'Crazysun Sonnenstudio | Preise',
        description: `Festpreis für alle Bräunungssitzungen`,
      }}/>
 
      <main>
        <section className="section video-teaser-wrapper">
            <Zoom>
          <div className="container" style={{padding: '0 3em'}}>
            <div className="columns" style={{ alignItems: 'stretch'}}>

              <div class="column is-half card has-text-centered  is-offset-one-quarter ">
                <h3>Festpreis für alle Bräunungssitzungen</h3>
                <h2>
                  bis 20 Minuten&nbsp;
                  <b>€11,30</b>&nbsp; 
                  alle Betten,&nbsp;
                  Teilkörper 15 Minuten&nbsp;
                  <b>€9,60</b>
                </h2>
                <hr />
                <h3>Bei jeder Bräunungssitzung inkludiert</h3>
                <h3>Für alle unsere Kunden stehen folgende Leistungen kostenlos zur Verfügung.</h3>
                <br />
                <ul className="price-list">
                  <li>Kalte Getränke wie Apfelsaft,<br/> Orangensaft, Mineralwasser...</li>
                  <li>Deospray </li>
                  <li>Spray </li>
                  <li>Gel</li>
                  <li>Fön </li>
                  <li>Cremen </li>
                  <li>Abschminkmilch </li>
                  <li>Dusche </li>
                  <li>Handtuch </li>
                  <li>Schutzbrille</li>
                </ul>
              
              </div>
            </div>
            <div className="columns">

              <div class="column is-half has-text-centered card  is-offset-one-quarter ">
                <h2>Sonderangebot bei Chipkarten Aufladung</h2>
                <hr />
                <h3>bei jeder Aufladung ab € 106,00</h3>
                <br />

                <table style={{width: '100%', padding: '1em', textAlign: 'center'}}>
                  <tr align="center">
                    <th align="center">Sie zahlen</th>
                    <th align="center">Sie bekommen</th>
                  </tr>
                  <tr>
                    <td>€ 106,00</td>
                    <td>€ 113,00</td>
                  </tr>
                  <tr>
                    <td>€ 153,00</td>
                    <td>€ 169,50</td>
                  </tr>
                  <tr>
                    <td>€ 192,00</td>
                    <td>€ 226,00</td>
                  </tr>
                </table>

              
              </div>
            </div>
          </div>
            </Zoom>
        </section>
      </main>
    </Grid>
  )
}


export default Prices;