import React, { Fragment } from 'react';
import Logo from '../../media/logo.png';
import styled from 'styled-components';
import ContactUs from '../TeaserSections/ContactUs';
import Fade from 'react-reveal/Fade';
import NewCustomerTeaser from '../TeaserSections/NewCustomerTeaser';
import { Link } from 'react-router-dom';



const FooterWrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
  background: #212126;
  margin: 0;
  padding: 0;
  padding-top: 1em;

  iframe {
    width: 40%;
    flex-shrink: 1;
    flex-grow: 0;
    @media (max-width: 820px) {
      width: 100%;
    }
  }


  > div {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    @media (max-width: 820px) {
      width: 100%;
    }

    > div {
      width: calc(100% - 320px);
      display:flex;
      flex-direction: column;

      .last-item {
        flex-grow: 1;
        margin: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media (max-width: 820px) {
        width: 100%;
      }
    }

    

  }


  .level-i {
    width: 320px;
    padding: 1em 4em;
    display: flex;
    
    @media (max-width: 820px) {
      width: 100%;
      text-align: center;
      padding: 1em;
    }

    .title {
      margin-bottom: 0;
    }
  }

`;

const Footer = () => (
    <Fragment>
      <NewCustomerTeaser />
      <FooterWrapper>
        <Fade>
          <iframe 
            src="https://www.google.com/maps/d/embed?mid=1naxguCAmLhGhhspUNhdhGf3bHSKb5KG3" 
            width="640" 
            height="480" />
        </Fade>


        <div>

          <div className="level-i">
            <div>
              <p className="heading padding-top"><Link to="/">Home</Link></p>
              <p className="heading"><Link to="/about">Über uns</Link></p>
              <p className="heading"><Link to="/contact">Kontakt</Link></p>
              <p className="heading"><Link to="/impressum">Impressum</Link></p>
              <br/>
            </div>
            <div>
              <h3 class="title is-6 has-text-light">Standorte</h3>
              <p className="heading padding-top"><Link to="/offices/grundstrasse">Gudrunstraße 137-139</Link></p>
              <p className="heading"><Link to="/offices/simmering">SimmeringerHaupstraße 91</Link></p>
              <p className="heading"><Link to="/offices/ottakringer">Ottakringer Straße 75</Link></p>
              <p className="heading"><Link to="/offices/kaisermuehlen">Kaisermühlenstraße 24</Link></p>
              <br/>
            </div>
            <div>
              <h3 class="title is-6 has-text-light">Öffnungszeiten</h3>
              <p className="heading padding-top"><b>Mo-Fr</b>	09:00–22:00</p>
              <p className="heading"><b>Sa</b>	10:00–22:00</p>
              <p className="heading"><b>So</b>	10:00–22:00</p>
              <p style={{fontSize: '0.8em'}}>inkl. Feiertage</p>
            </div>
 
          </div>
          <div className="level-item">

            <div className="last-item">
              <img className="footer-logo" src={Logo} />
              <br />

              <p className="heading padding-top has-text-centered has-text-light">Das Sonnenstudio mit dem besonderen Flair</p>
              <p className="heading has-text-centered"><a href="mailto:info@crazysun.at">info@crazysun.at</a></p>
              <p className="has-text-centered">
                <a className="is-facebook" href="https://www.facebook.com/crazysunwien/" target="_blank">
                  <span className="icon is-small">
                      <i className="fa fa-facebook"></i>
                  </span>
                </a>
                <a className="is-instagram" href="https://www.instagram.com/crazy_sun_wien/" target="_blank">
                  <span className="icon is-small">
                      <i className="fa fa-instagram"></i>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </FooterWrapper>
    </Fragment>
);

export default Footer;
