import React from 'react';
import Hero from '../../components/Layout/Hero';
import OfficesTeaser from '../../components/TeaserSections/OfficesTeaser';
import SEO from '../../components/SEO';
import Grid from '../../components/Layout/Grid';



const tabItems = [
  { name: 'GUDRUNSTRASSE 137-139', href: "offices/grundstrasse",},
  { name: 'SIMMERINGER HAUPTSTRAßE 91', href: "offices/simmering"},
  { name: 'OTTAKRINGER STRASSE 75', href: "offices/ottakringer"},
  { name: 'KAISERMÜHLENSTRASSE 24', href: "offices/kaisermuehlen"},
]


const Offices = ({ match }) => {

  return (
    <Grid>
      <SEO {...{
        title: 'Crazysun Sonnenstudio | Standorte',
        description: `DAS BESTE BRÄUNUNGSANLAGE  IM HERZEN DES WIENS! `,
      }}/>
 
      <Hero 
        tabItems={tabItems}
        bgClass="default" 
        heroContent={(
          <h2><br />Unsere Filialen</h2>
        )}
      />
      <main>
        <OfficesTeaser />
      </main>
    </Grid>
  )
}


export default Offices;