
import React, { Fragment} from 'react';
import Hero from '../components/Layout/Hero';
import VideoTeaser from '../components/TeaserSections/VideoTeaser';

import MakeAnAppointment from '../components/Forms/MakeAnAppointment';
import OfficesTeaser from '../components/TeaserSections/OfficesTeaser';
import Zoom from 'react-reveal/Zoom';

import E950 from '../media/products/beds/950.jpg';
import P1000 from '../media/products/beds/p100.jpg';
import E750 from '../media/products/beds/750.jpg';
import IQ from '../media/products/beds/iq.jpg';
import { StyledProductsWrapper } from './Products/Product';
import ProductCard from '../components/ProductCard';
import SEO from '../components/SEO';
import Grid from '../components/Layout/Grid';
import HomeImageGallery from '../components/TeaserSections/HomeImageGallery';
import { Link } from 'react-router-dom'


import styled from 'styled-components';


const StyledLink = styled(Link)`

  color: #fff !important;


`;



const products = [
  {
    name: 'Unsere Produkte',
    description: (
      <Fragment>
        <StyledLink to="products/beds" className="button is-primary is-normal">Alle Produkte Zeigen</StyledLink>
      </Fragment>
    ),
    image: P1000 
  },

  {
    name: 'Ergoline Prestige 1000',
    description: `Die Prestige 1000 ist der Einstieg in die neue Luxusklasse von Ergoline. Mit Weltneuheiten wie... `,
    image: P1000
  },
  {
    name: 'Ergoline IQ',
    description: `51 UV-Lampen à 120-180 Watt im Körper-Bereich und 4 Gesichts-Bräuner à 260-520 Watt...`,
    image: IQ
  },
  {
    name: 'Ergoline Esprit 750',
    description: 'Die Ergoline Esprit 750 Dynamic Power strahlt mit ihren markanten Linien und leuchtenden...',
    image: E750
  }
]


const StyledProductHeader = styled.div`

  text-align: right;
  flex-grow: 1;
  justify-content:center;
  align-content: center;
  align-items: center;
  padding: 1em;
  width: 50%;

`;

const Home = () => {

  return (
    <Grid>
      <SEO {...{
        title: 'Crazysun Sonnenstudio',
        description: 'Bei Crazysun haben wir uns auf Kundenservice spezialisiert und haben bis ins kleinste Detail darauf geachtet. Wir bieten modernste Ergoline-Geräte in einer sauberen und komfortablen Atmosphäre.',
      }} />
      <Hero 
        bgClass="home-header-bg"
        heroContent={(<MakeAnAppointment />)}
      />

      <main>
        <VideoTeaser />
        <HomeImageGallery />
        <OfficesTeaser />

        <section className="section video-teaser-wrapper is-full">
          <div className="container">
            <Zoom>
              <StyledProductsWrapper>
                  {products.map((product, i) => (
                    i === 0 ? (
                      <StyledProductHeader>
                        <h2>{product.name}</h2>
                        <p>
                          Zarte Aromen und feiner Sprühnebel entspannen oder vitalisieren Körper und Geist je nach Wunsch und Einstellung. 
                        </p>
                        {product.description}
                      </StyledProductHeader>
                    ):
                    <ProductCard
                      name={product.name} 
                      description={product.description}
                      image={product.image}
                      index={i}
                    />
                  )
                  )}
              </StyledProductsWrapper>
            </Zoom>
          </div>
        </section>
      </main>
    </Grid>
  )
}


export default Home;
