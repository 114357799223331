import React from 'react';
import styled, { keyframes } from 'styled-components';

import Zoom from 'react-reveal/Zoom';
import Lightbox from 'react-images';



import I221 from '../../media/offices/22/22-000.png';


import I115 from '../../media/offices/11/22-015.png';
import I111 from '../../media/offices/11/22-004.png';
import I161 from '../../media/offices/16/22-004.png';
import I114 from '../../media/offices/11/22-016.png';
import I166 from '../../media/offices/16/22-001.png';
import I223 from '../../media/offices/22/22-008.png';


const photos = [
      { src: I221, width: 4, height: 3 },
      { src: I223, width: 4, height: 3 },
      { src: I166, width: 4, height: 3 },
      { src: I115, width: 4, height: 3 },
      { src: I111, width: 4, height: 3 },
      { src: I161, width: 2, height: 3 },
      { src: I114, width: 2, height: 3 },
];



const fronte = keyframes`
  from { z-index: 0; transform: scale(0.95); opacity: 0.95; }
  to { z-index: 4; transform: scale(1); opacity: 1; }
`


  // box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;


  @media (max-width: 980px) {
    width: 100%;
  }

`;

const Wrapper = styled.div`

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);


  width: 900px;
  height: 100vh;
  padding-bottom: 2em;



  @media (max-width: 1200px) {
    width: 600px;
  }
  
  @media (max-width: 980px) {
    width: 100%;
    height: 70vh;
  }

  @media (max-width: 790px) {
    width: 100%;
    height: 60vh;
  }

  @media (max-width: 580px) {
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    width: 100%;
    height: 100%;
    >div {
      width: 50%;
      height: 10vh;

      &:first-of-type {
        flex-grow: 1;
      }
    }

  }

  div {

    box-shadow: -6px 3px 34px 6px rgba(0,0,0,0.75);
    cursor: pointer;



    &:hover {
      animation: fronte 0.5s;
    }

  }
  
  @media (min-width: 580px) {
    .g31  { 
      grid-area: 1 / 1 / 4 / 4; 
    }
    .g32  { 
      grid-area: 3 / 2 / 6 / 4; 
      margin-left: -0.3em;
      margin-right: 0.3em;
    }
    .g33  { 
      grid-area: 4 / 3 / 7 / 6; 
      margin-left: -0.5em;
      margin-right: 0.5em;
      margin-bottom: -1em;
      margin-top: 1em;
      z-index: 2;
    }
    .g34  { 
      grid-area: 5 / 1 / 7 / 3; 
      margin-right: -1.5em;
      margin-left: 1.5em;
      margin-top: -1em;
      margin-bottom: 1em;
    }
    .g35  { 
      grid-area: 2 / 4 / 5 / 7; 
      margin-left: -0.3em;
      margin-right: 0.3em;
    }
    .g36  { 
      grid-area: 5 / 6 / 6 / 7; 
      margin-left: -1.5em;
      margin-right: 1.5em;
      margin-top: -2em;
      margin-bottom: 2em;
      z-index: 5;
    }
    .g37  { 
      grid-area: 1 / 4 / 3 / 5; 
      margin-left: -1em;
      margin-right: 1em;
      margin-top: -1.5em;
      margin-bottom: 1.5em;
    }
  }

`;


class HomeImageGallery extends React.Component {

  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }


  render() {
    return (
      <Container>

        <Wrapper>
          {[...(this.props.photos ? this.props.photos : photos)].map((p, i) =>(
            <Zoom ssrFadeout>
              <div 
                className={`g3${i+1}`} 
                key={`img-${i}`} 
                style={{
                  minHeight: '100%',
                  backgroundImage: `url(${p.src})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center'
                }} 
                onClick={(e) => {
                  this.openLightbox(e, { index: i});
                }}
              />
            </Zoom>
          ) )}
        </Wrapper>
        <Lightbox images={this.props.images || photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </Container>
    )
  }
}



export default HomeImageGallery;