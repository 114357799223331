import React from 'react';

import Logo from '../../media/logo.png';

import Fade from 'react-reveal/Fade';

const MakeAnAppointment = () => {

  return (
    <div className="has-text-right withLogo">
      <Fade>
          <img className="header-logo" src={Logo} />
          <h1 className={"teaser-herz"}>Die beste <br/> Bräunungsanlage <br/> im herzen Wiens!<br /></h1>
      </Fade>
    </div>
  );
};


export default MakeAnAppointment;
