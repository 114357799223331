import React from 'react';
import Hero from '../components/Layout/Hero';
import OfficesTeaser from '../components/TeaserSections/OfficesTeaser';
import SectionPlaceholder from '../components/TeaserSections/SectionPlaceholder';
import SEO from '../components/SEO';
import Grid from '../components/Layout/Grid';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';


const About = () => {

  return (
    <Grid>

      <SEO {...{
        title: 'Crazysun Sonnenstudio | Über Uns',
        description: `
          Vor achtzig Jahren, als Sonneneinstrahlung zum ersten Mal mit Hautkrebs in Verbindung gebracht wurde, wurde die Bräunung durch die populäre Kultur hervorgehoben, 
          indem betont wurde, dass "eine feine braune Farbe Gesundheit und gute Zeiten suggeriert und eine angenehme Sache ist, zu sehen."
        `,
      }}/>
      <Hero bgClass="aboutUs" heroContent={(<h2><br />Über Uns</h2>)}/>

      <main>
        <section class="section ">
          <div className="container is-fullhd has-text-centered">
            <h1 className="title is-3 is-capitalized">CRAZYSUN SONNENSTUDIOS</h1>
            <br />
          </div>
        </section>

        <section className="section">
            <div className="container">
              <div className="columns">
                  <div className="column is-6 vcol-wrapper has-text-right">
                  <Fade>

                    <div>
                      <h4 className="title is-4 is-capitalized">ein bisschen über das Bräunen</h4>
                      <p>
                        Vor achtzig Jahren, als Sonneneinstrahlung zum ersten Mal mit Hautkrebs in Verbindung gebracht wurde, wurde die Bräunung durch die populäre Kultur hervorgehoben, indem betont wurde, dass <small><i>"eine feine braune Farbe Gesundheit und gute Zeiten suggeriert, sowie schön anzusehen ist."</i></small>
                      <p>
                      <br/>
                      </p>
                        Wir wissen, dass Sonneneinstrahlung tödlich sein kann, und heutige öffentliche Sensibilisierungskampagnen konzentrieren sich stark auf die Vermeidung von Sonnenstrahlen, um Hautkrebs zu verhindern. Wir wissen aber auch, dass Sonnenlicht für unsere Gesundheit wichtig ist und in vielen biologischen Prozessen in unserem Körper eine Rolle spielt.
                      </p>
                      <br />
                      <br />
                    </div>
                  </Fade>
                    <Zoom>
                      <img style={{marginTop: '2em'}} src="https://www.aktivhaus-schwalmstadt.de/_media/images/content/leistungen/600X465/leistungen-solarium.jpg" />
                    </Zoom>
                  </div>
                  <div className="column is-6 vcol-wrapper">
                    <Zoom>
                      <img style={{marginBottom: '2em'}} src="https://www.wunderweib.de/assets/styles/article_image/public/field/image/652/solarium-artikel.jpg?itok=G7j14wfR" />
                    </Zoom>
                    <br />
                    <br />
                    <Fade>

                    <div>
                      <h4 className="title is-4 is-capitalized">Wie können wir helfen?</h4>
                      <p>
                        Wie bei allem in diesem Leben dreht es sich um Maß und Ziel. UV-Strahlen können gut sein, aber Ihr Körper braucht sie nur in geringer Menge. Wenn Sie zu viele Strahlen abbekommen, wendet sich all das Gute, dass sie im Körper bewirken, wieder ins Negative!
                      </p>
                      <br />
                      <p>
                        Unser professionelles Team ist immer für Sie da, um die Vorteile von `Tanning` sowie die Konsequenzen von Überexposition zu erklären. Damit Sie Ihre Bräunungssitzungen entsprechend planen können, um vollen Gesundheitsnutzen zu erhalten und gleichzeitig vor schädlichen Seiten des Bräunens geschützt zu werden.
                      </p>
                    </div>
                    </Fade>
                  </div>
              </div>
            </div>
            <br />
            <br />
        </section>

        <OfficesTeaser className="video-teaser-wrapper" />
      </main>
    </Grid>
  )
}


export default About;